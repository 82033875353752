<template>
  <v-card class="transparent ma-0 pa-0 elevation-0">
    <v-card-title class="case-study-title pb-0">
      <span> {{ String(value.name).toUpperCase() }}</span>

      <a class="demo-link" :href="value.web_url" target="blank">
        {{ value.web_url }}</a
      >
    </v-card-title>

    <v-card-text>
      <p class="simple-text mb-0" v-html="value.summary"></p>

      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="ma-2"
            :color="item.tag.color"
            @click="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
      <v-divider></v-divider>

      <v-row class="mx-0">
        <v-col cols="6" class="examples-col flex-col ma-0">
          <v-carousel
            hide-delimiter-background
            show-arrows-on-hover
            v-if="
              permissions[
                CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_PUBLIC_MATERIALS
              ] &&
              value.publicMaterials &&
              value.publicMaterials.length
            "
            height="300"
          >
            <template v-slot:prev="{ on, attrs }">
              <div class="direction-wrapper-light">
                <v-icon
                  class="direction-icon"
                  color="black"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-chevron-left</v-icon
                >
              </div>
            </template>
            <template v-slot:next="{ on, attrs }">
              <div class="direction-wrapper-light">
                <v-icon
                  class="direction-icon"
                  color="black"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-chevron-right</v-icon
                >
              </div>
            </template>
            <v-carousel-item
              contain
              v-for="(material, i) in value.publicMaterials"
              :key="i"
              :src="material.url"
            >
            </v-carousel-item>
          </v-carousel>
          <v-rating
            :value="4.5"
            color="amber"
            dense
            half-increments
            readonly
            size="14"
          ></v-rating>
          <div class="grey--text ms-4">4.5 (413)</div>
        </v-col>
        <v-col cols="6" class="pa-0 ma-0 flex-col">
          <h3 class="my-2">BRIEF:</h3>
          <p class="simple-text" v-html="value.brief"></p>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 flex-col-300">
          <h3 class="my-2">CHALLENGES:</h3>
          <p class="simple-text" v-html="value.challenges"></p>
        </v-col>
        <v-col class="pa-0 flex-col-300">
          <h3 class="my-2">SOLUTION:</h3>
          <p class="simple-text" v-html="value.solution"></p>
        </v-col>
      </v-row>

      <h3 class="my-2">WHAT HAS BEEN DONE:</h3>
      <p class="simple-text" v-html="value.what_has_been_done"></p>

      <h3 class="my-2">MARKETING:</h3>
      <p class="simple-text" v-html="value.marketing_description"></p>

      <!-- Deliverables -->
      <FormSection
        underline
        right
        :label="'Deliverables'"
        :icon="'mdi-file-document-multiple-outline'"
        :actions="deliverableActions"
        :editable="!!deliverableActions.length"
        :payload="payload"
      ></FormSection>

      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.deliverables && value.deliverables.length"
      >
        <v-slide-item
          v-for="item in value.deliverables"
          :key="item.deliverable.id"
        >
          <v-chip
            class="ma-2"
            @click="() => $emit('onDeliverableClick', item.deliverable)"
            text-color="white"
          >
            {{ item.deliverable.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>

      <!-- Service -->
      <FormSection
        underline
        right
        :label="'Services'"
        :icon="'mdi-briefcase'"
        :actions="serviceActions"
        :editable="!!serviceActions.length"
        :payload="payload"
      ></FormSection>

      <v-slide-group
        v-model="tagIndex"
        class="pt-4 pb-4"
        center-active
        show-arrows
        v-if="value.services && value.services.length"
      >
        <v-slide-item v-for="item in value.services" :key="item.id">
          <ServiceItemSmall
            class="ma-2"
            @click="() => $emit('onServiceClick', item.service)"
            :value="item.service"
          ></ServiceItemSmall>
        </v-slide-item>
      </v-slide-group>

      <!-- Useful Links -->
      <FormSection
        underline
        right
        class="mt-0"
        :label="'Useful Links'"
        :icon="'mdi-link-variant'"
        :actions="linkActions"
        :editable="!!linkActions.length"
        :payload="payload"
      ></FormSection>

      <LinksList
        v-if="value.links && value.links.length > 0"
        :value="value.links"
      ></LinksList>

      <!-- Materials -->
      <FormSection
        underline
        right
        class="mt-0"
        :label="'Materials'"
        :icon="'mdi-file-document-multiple'"
        :actions="fileActions"
        :editable="!!fileActions.length"
        :payload="payload"
      ></FormSection>
      <v-alert
        class="my-2"
        dense
        outlined
        type="success"
        v-if="
          permissions[
            CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_PUBLIC_MATERIALS
          ] &&
          value.publicMaterials &&
          value.publicMaterials.length
        "
      >
        Files below are <strong>PUBLIC</strong> and can be shared with a
        customer without any additional procedures.
      </v-alert>
      <v-row
        class="pa-0 ma-0"
        v-if="
          permissions[
            CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_PUBLIC_MATERIALS
          ] &&
          value.publicMaterials &&
          value.publicMaterials.length
        "
      >
        <v-col cols="12">
          <div class="theme-default">
            <div class="vue-file-agent grid-block-wrapper">
              <VueFilePreview
                v-for="(fileRecord, i) in value.publicMaterials"
                :key="i"
                :value="fileRecord"
                :linkable="true"
                class="file-preview-wrapper grid-box-item grid-block"
              ></VueFilePreview>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-alert
        class="my-2"
        dense
        outlined
        type="warning"
        v-if="
          permissions[
            CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_CUSTOMER_MATERIALS
          ] &&
          value.customerMaterials &&
          value.customerMaterials.length
        "
      >
        Files below can be shared with a customer
        <strong> ONLY after NDA signing</strong>. Please make sure that NDA
        exists.
      </v-alert>
      <v-row
        class="pa-0 ma-0"
        v-if="
          permissions[
            CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_CUSTOMER_MATERIALS
          ] &&
          value.customerMaterials &&
          value.customerMaterials.length
        "
      >
        <v-col cols="12">
          <div class="theme-default">
            <div class="vue-file-agent grid-block-wrapper">
              <VueFilePreview
                v-for="(fileRecord, i) in value.customerMaterials"
                :key="i"
                :value="fileRecord"
                :linkable="true"
                class="file-preview-wrapper grid-box-item grid-block"
              ></VueFilePreview>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-alert
        class="my-2"
        dense
        outlined
        type="error"
        v-if="
          permissions[
            CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_PRIVATE_MATERIALS
          ] &&
          value.privateMaterials &&
          value.privateMaterials.length
        "
      >
        Files below are
        <strong>FULLY PRIVATE</strong> and are protected by ADAAS IP. These
        files for internal usage <strong>ONLY</strong>.
      </v-alert>

      <v-row
        class="pa-0 ma-0"
        v-if="
          permissions[
            CASE_STUDY_PERMISSIONS.ALLOW_SEE_CASE_STUDY_PRIVATE_MATERIALS
          ] &&
          value.privateMaterials &&
          value.privateMaterials.length
        "
      >
        <v-col cols="12">
          <div class="theme-default">
            <div class="vue-file-agent grid-block-wrapper">
              <VueFilePreview
                v-for="(fileRecord, i) in value.privateMaterials"
                :key="i"
                :value="fileRecord"
                :linkable="true"
                class="file-preview-wrapper grid-box-item grid-block"
              ></VueFilePreview>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
import { CASE_STUDY_PERMISSIONS } from "../../../../../views/src/apps/knowledge-base/constants/permissions.gh";
import FormSection from "../../../atoms/common/FormSection.vue";
import ServiceItemSmall from "../../services/ServiceItemSmall.vue";
import LinksList from "../links/LinksList.vue";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";
import { CASE_STUDY_BUTTONS } from "../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import {
  SERVICE_BUTTONS,
  DELIVERABLE_BUTTONS,
} from "../../../../../views/src/apps/service-management/constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FormSection,
    LinksList,
    ServiceItemSmall,
  },
  data() {
    return {
      tagIndex: null,
      CASE_STUDY_PERMISSIONS,
      CASE_STUDY_BUTTONS,
      getActions: PermissionsHelper.getActions,
    };
  },
  computed: {
    deliverableActions: function () {
      return PermissionsHelper.getActions(
        [DELIVERABLE_BUTTONS.LIST, CASE_STUDY_BUTTONS.GENERAL_EDITOR],
        this.permissions
      );
    },
    serviceActions: function () {
      return PermissionsHelper.getActions(
        [SERVICE_BUTTONS.LIST, CASE_STUDY_BUTTONS.GENERAL_EDITOR],
        this.permissions
      );
    },
    linkActions: function () {
      return PermissionsHelper.getActions(
        CASE_STUDY_BUTTONS.GENERAL_EDITOR,
        this.permissions
      );
    },
    fileActions: function () {
      return PermissionsHelper.getActions(
        CASE_STUDY_BUTTONS.FILES_EDITOR,
        this.permissions
      );
    },
  },
  async created() {},
  methods: {
    async copyLink() {
      await navigator.clipboard.writeText(this.value.web_url);
    },
  },
  watch: {},
};
</script>


<style lang="scss" scoped>
.case-study-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.demo-link {
  display: block;
}

.examples-col {
  min-width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>