<template>
  <AView
    :value="value"
    :actions="actions"
    :active="caseStudy.id === activeCaseStudyId"
    :name="caseStudy.name"
    ref="caseStudy-editor-view"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onHover="
      $store.commit(
        'CaseStudyStore/setActiveId',
        value.relation.params.caseStudyId
      )
    "
    :payload="{
      from: value,
      caseStudy,
    }"
  >
    <template v-slot:content="{}">
      <CaseStudyDetailsVue
        :permissions="value.permissions"
        :value="caseStudy"
        :payload="{
          from: value,
          caseStudy,
        }"
        @onDeliverableClick="onDeliverableClick"
        @onServiceClick="onServiceClick"
      ></CaseStudyDetailsVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import CaseStudyDetailsVue from "../../../../../../components/wad/organisms/knowledgeBase/caseStudies/CaseStudyDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { CASE_STUDY_BUTTONS } from "../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    CaseStudyDetailsVue,
  },
  data: () => ({
    caseStudy: {},
  }),
  computed: {
    ...mapState("CaseStudyStore", [
      "activeCaseStudyId",
      "displayedCaseStudies",
    ]),
    ...mapGetters("CaseStudyStore", ["caseStudyById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          CASE_STUDY_BUTTONS.GENERAL_EDITOR_MAIN,
          CASE_STUDY_BUTTONS.FILES_EDITOR_MAIN,
          CASE_STUDY_BUTTONS.SETTINGS_EDITOR_MAIN,
        ],
        this.value.permissions
      );
    },
  },
  created() {
    this.$store.dispatch("CaseStudyStore/GetCaseStudy", {
      id: this.value.relation.params.caseStudyId,
    });

    this.caseStudy = this.caseStudyById(this.value.relation.params.caseStudyId);
  },
  methods: {
    onDeliverableClick(deliverable) {
      this.$store.dispatch("DeliverableStore/SetActiveDeliverable", {
        from: this.value,
        deliverable,
      });
    },
    onServiceClick(service) {
      this.$store.dispatch("ServiceStore/SetActiveService", {
        from: this.value,
        service,
      });
    },
  },
  watch: {
    displayedCaseStudies: {
      async handler(newVal) {
        if (newVal) {
          this.caseStudy = this.caseStudyById(
            this.value.relation.params.caseStudyId
          );
        }
      },
      deep: true,
    },
  },
};
</script>