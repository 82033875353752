var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{ref:"caseStudy-editor-view",attrs:{"value":_vm.value,"actions":_vm.actions,"active":_vm.caseStudy.id === _vm.activeCaseStudyId,"name":_vm.caseStudy.name,"payload":{
    from: _vm.value,
    caseStudy: _vm.caseStudy,
  }},on:{"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onExpand":(e) => _vm.$emit('onExpand', e),"onCollapse":(e) => _vm.$emit('onCollapse', e),"onClose":(e) => _vm.$emit('onClose', e),"onHover":function($event){return _vm.$store.commit(
      'CaseStudyStore/setActiveId',
      _vm.value.relation.params.caseStudyId
    )}},scopedSlots:_vm._u([{key:"content",fn:function({}){return [_c('CaseStudyDetailsVue',{attrs:{"permissions":_vm.value.permissions,"value":_vm.caseStudy,"payload":{
        from: _vm.value,
        caseStudy: _vm.caseStudy,
      }},on:{"onDeliverableClick":_vm.onDeliverableClick,"onServiceClick":_vm.onServiceClick}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }